import { getDefaultNormalizer } from '@testing-library/react';
import React, {  useState } from 'react'
 import axios from 'axios';
const nodemailer = require('nodemailer');


function TestForm() {

const [firstName, setFirstName] = useState("Ian")
const [lastName, setLastName] = useState("Brown")
const [mailSent, setMailSent] = useState(false)
const [reportError, setReportError] = useState(null)

const handleFirstNameChange = (e) => {
  setFirstName(e.target.value)
}

const handleLastNameChange = (e) => {
  setLastName(e.target.value)
}

const handleFormSubmit = (e) => {
  e.preventDefault();
  console.log("First name>: " + firstName);
  console.log("Last name>: " + lastName);

  var transporter = nodemailer.createTransport({

    service: 'kualo',
    auth: {
      user: 'ian@jaiwebs.co.uk',
      pass: 'ILSJ_216]'
    }

  }) // end of createTransport

  var mailOptions = {
    from: 'ian@jaiwebs.co.uk',
    to: 'bashwust@gmail.com',
    subject: 'Sending email with node.js',
    text: 'Sending email with node.jsSending email with node.jsSending email with node.jsSending email with node.js'
  };

  transporter.sendMail( mailOptions, function(error, info){
    if(error){
      console.log(error);
    } else{
      console.log("Email sent: " + info);
    }
  })



} // end of handleFormSubmit

  return (
    <div>

        <form onSubmit={handleFormSubmit} >
            <input type="text" name="first_name" value={firstName} onChange={handleFirstNameChange}  />
            <input type="text" name="last_name" value={lastName} onChange={handleLastNameChange}  />
            <input type="submit" value="Send" />
        </form>


    </div>
  )
}

export default TestForm